// src/Auth0ProviderWithHistory.js
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {auth0Config} from "../helpers/auth0-config"; // Adjust the path if needed

// Create a User Context
const UserContext = createContext();

// Custom hook for accessing user info
export const useUser = () => useContext(UserContext);

const Auth0ProviderWithHistory = ({ children }) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={auth0Config.domain}
      clientId={auth0Config.clientId}
      authorizationParams={{ redirect_uri: auth0Config.redirectUri }}
      onRedirectCallback={onRedirectCallback}
    >
      <UserProvider>{children}</UserProvider>
    </Auth0Provider>
  );
};

// UserProvider to fetch and store user info
const UserProvider = ({ children }) => {
  const { user, isAuthenticated } = useAuth0();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (isAuthenticated && user) {
      setUserInfo({
        username: user.sub,
        customerName: user.nickname || "Guest",
        domain: auth0Config.domain
      });
    }
  }, [user, isAuthenticated]);

  return (
    <UserContext.Provider value={{ userInfo }}>
      {children}
    </UserContext.Provider>
  );
};

export default Auth0ProviderWithHistory;
