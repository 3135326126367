import { Navigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import {useAuth} from "../../helpers/auth-context";
import {useUrlContext} from "../../helpers/url-context";

function PrivateRoute({ children, requiredRole }) {
  const { isAuthenticated, roles, loadingRoles } = useAuth();
  const [shouldRender, setShouldRender] = useState(false);
  const location = useLocation(); // Get the full current URL
  const currentWindow = location.pathname + location.search; // Preserve search parameters
  const {setPreviousLocation } = useUrlContext();

  useEffect(() => {
    // Trigger re-render when roles are updated
    if (!loadingRoles) {
      setShouldRender(true);
    }
  }, [loadingRoles]);

  // Store the full URL before redirecting to login
  if (
    currentWindow &&
    currentWindow !== "/login-register" &&
    !currentWindow.includes("google") &&
    !currentWindow.includes("facebook")
  ) {
    setPreviousLocation(currentWindow); // Store the entire pathname + search params
  }

  console.log("PrivateRoute requiredRole:", requiredRole);
  console.log("PrivateRoute roles:", roles);

  if (!isAuthenticated) {
    return <Navigate to="/login-register" state={{ from: location }} replace />;
  }

  if (loadingRoles || !shouldRender) {
    return <div>Loading...</div>; // Or any loading spinner component
  }

  if (requiredRole && (!roles || roles.length === 0 || !roles.includes(requiredRole))) {
    return <Navigate to="/unauthorized" />;
  }

  return children;
}

export default PrivateRoute;
